import { createContext, ReactNode, useContext, useState } from 'react';

import usePxAuthContext, { LoginMethod } from 'src/authentication/usePxAuthContext';

interface IAuthModalContext {
  openWalletModal: boolean;
  setOpenWalletModal: (open: boolean) => void;
  openEmailModal: boolean;
  setOpenEmailModal: (open: boolean) => void;
  hasEmailLoginForm: boolean;
  setHasEmailLoginForm: (hasForm: boolean) => void;
  hasEmailRegisterForm: boolean;
  setHasEmailRegisterForm: (hasForm: boolean) => void;
  hasEmailResetForm: boolean;
  setHasEmailResetForm: (hasForm: boolean) => void;
  loginWithGoogle: (returnTo?: string) => void;
  loginWithOAuth2: (slug: string, returnTo?: string) => void;
  onClose: () => void;
}

export const AuthModalContext = createContext<IAuthModalContext>({
  openWalletModal: false,
  setOpenWalletModal: () => {},
  openEmailModal: false,
  setOpenEmailModal: () => {},
  hasEmailLoginForm: false,
  setHasEmailLoginForm: () => {},
  hasEmailRegisterForm: false,
  setHasEmailRegisterForm: () => {},
  hasEmailResetForm: false,
  setHasEmailResetForm: () => {},
  loginWithGoogle: () => {},
  loginWithOAuth2: () => {},
  onClose: () => {},
});

type Props = {
  onClose: () => void;
  children: ReactNode;
};

function AuthModalProvider({ onClose, children }: Props) {
  const { login } = usePxAuthContext();
  const [openWalletModal, setOpenWalletModal] = useState(false);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [hasEmailLoginForm, setHasEmailLoginForm] = useState(true);
  const [hasEmailRegisterForm, setHasEmailRegisterForm] = useState(true);
  const [hasEmailResetForm, setHasEmailResetForm] = useState(true);

  const loginWithGoogle = (returnTo?: string) => {
    login(LoginMethod.GoogleOAuth2, { returnTo })
      .then(onClose)
      .catch(console.error);
  };

  const loginWithOAuth2 = (slug: string, returnTo?: string) => {
    login(LoginMethod.OAuth2, { slug, returnTo })
      .then(onClose)
      .catch(console.error);
  };

  const value: IAuthModalContext = {
    openWalletModal,
    setOpenWalletModal,
    openEmailModal,
    setOpenEmailModal,
    hasEmailLoginForm,
    setHasEmailLoginForm,
    hasEmailRegisterForm,
    setHasEmailRegisterForm,
    hasEmailResetForm,
    setHasEmailResetForm,
    loginWithGoogle,
    loginWithOAuth2,
    onClose,
  };

  return (
    <AuthModalContext.Provider value={value}>
      {children}
    </AuthModalContext.Provider>
  );
}

export function useAuthModalContext() {
  const context = useContext<IAuthModalContext>(AuthModalContext);
  if (!context) {
    throw new Error('useAuthModalContext must be used within an AuthModalContextProvider');
  }
  return context;
}

export default AuthModalProvider;
