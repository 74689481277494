import WideButton from '../WideButton';

import icon from '../../../../../assets/svg/lock-duotone-light.svg';
import { useAuthModalContext } from '../AuthModalContextProvider';

type Props = {
  className?: string;
  slug: string;
  returnTo?: string;
};

export default function OAuth2LoginWideButton({ className, slug, returnTo }: Props) {
  const { loginWithOAuth2 } = useAuthModalContext();
  return (
    <WideButton
      classes={{ button: className }}
      icon={icon}
      onClick={() => loginWithOAuth2(slug, returnTo)}
    >
      {slug.toUpperCase()}
    </WideButton>
  );
}
