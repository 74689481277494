import { StreamerStatus } from '@pureweb/platform-sdk';
import { useEffect, useState } from 'react';

import usePxAuthContext from 'src/authentication/usePxAuthContext';
import PureWebAvatarModal from 'src/components/ReadyPlayerMe/PureWebAvatarModal';
import Background from 'src/features/PixelStreaming/components/Background/Background';
import ContentViewer from 'src/features/PixelStreaming/components/ContentViewer/ContentViewer';
import HUD from 'src/features/PixelStreaming/components/HUD';
import LaunchContainer from 'src/features/PixelStreaming/components/LaunchContainer/LaunchContainer';
import PointsOfInterest from 'src/features/PixelStreaming/components/PointsOfInterest';
import ServerBrowserModalContainer from 'src/features/PixelStreaming/components/ServerBrowserModalContainer';
import SettingsOverlay from 'src/features/PixelStreaming/components/SettingsOverlay';
import TutorialOverlay from 'src/features/PixelStreaming/components/TutorialOverlay/TutorialOverlay';
import useDebugEmitUIInteract from 'src/features/PixelStreaming/hooks/useDebugEmitUIInteraction';
import useInitialPacket from 'src/features/PixelStreaming/hooks/useInitialPacket';
import useJoinGameSession from 'src/features/PixelStreaming/hooks/useJoinGameSession';
import useJoinSpace from 'src/features/PixelStreaming/hooks/useJoinSpace';
import useOpenExternalLink from 'src/features/PixelStreaming/hooks/useOpenExternalLink';
import useOpenModal from 'src/features/PixelStreaming/hooks/useOpenModal';
import useProfileUpdate from 'src/features/PixelStreaming/hooks/useProfileUpdate';
import useRefreshToken from 'src/features/PixelStreaming/hooks/useRefreshToken';
import useUserJoinAndExit from 'src/features/PixelStreaming/hooks/useUserJoinAndExit';
import EmbeddedView from 'src/features/PureWeb/components/EmbeddedView';
import PureWebAudioComponent from 'src/features/PureWeb/components/PureWebAudioComponent';
import PureWebContainer from 'src/features/PureWeb/components/PureWebContainer';
import PureWebContextProvider, { usePureWebContext } from 'src/features/PureWeb/contexts/PureWebContextProvider';
import UsageLimitReachedModal from 'src/features/Usage/components/UsageLimitReachedModal';
import UsageLimitReachedScreen from 'src/features/Usage/components/UsageLimitReachedScreen';
import UsageContextProvider, { useUsageContext } from 'src/features/Usage/contexts/UsageContextProvider';
import { MetaverseResponse } from 'src/interfaces/IMetaverse';
import { Space3DPage as ISpace3DPage } from 'src/interfaces/IPage';
import { useGetRandomGameLiftSession } from 'src/queries/gamelift';
import LoadingText from 'src/features/PixelStreaming/components/LoadingInfo/LoadingText';

import UploadContentModalContainer from '../../components/UploadContentModal/UploadContentModalContainer';
import PointsDisplay from 'src/features/PixelStreaming/components/PointsDisplay';

export interface Space3DPageProps {
  page: ISpace3DPage;
  metaverse: MetaverseResponse;
}
function Space3DPage({ page, metaverse }: Space3DPageProps) {
  const { space, metaverseId } = page;

  const { hasUsage } = useUsageContext();

  const { mapName, purewebConfiguration } = space.environment;
  const { aliasId } = purewebConfiguration;

  const { data: randomGameLiftSession } = useGetRandomGameLiftSession(metaverseId, aliasId, mapName);

  useEffect(() => {
    if (!randomGameLiftSession) return;
    setGameSessionId(randomGameLiftSession.GameSessionId);
  }, [randomGameLiftSession]);

  const {
    launch,
    isReady,
    isLaunching,
    isLaunched,
    isLocalPixelstreaming,
    videoStream,
    streamerStatus,
    emitter,
    status,
  } = usePureWebContext();

  const { user, authToken } = usePxAuthContext();

  const [gameSessionId, setGameSessionId] = useState('');
  useJoinGameSession(gameSessionId);
  useInitialPacket(
    metaverseId,
    undefined,
    authToken,
    user,
    gameSessionId,
  );
  useRefreshToken();
  useOpenExternalLink();
  useOpenModal();
  useJoinSpace(space._id);
  useProfileUpdate();
  useDebugEmitUIInteract();
  useUserJoinAndExit();

  return (
    <>
      <PureWebContainer>
        {page.backgroundImage && streamerStatus !== StreamerStatus.Connected && <Background source={{ src: page.backgroundImage, srcType: 'image' }} />}
        <UsageLimitReachedScreen isOwner={false} logo={page.space.image} />
        {
          hasUsage &&
          <EmbeddedView
            videoStream={videoStream}
            streamerStatus={streamerStatus}
            inputEmitter={emitter}
          >
            <HUD />
            <PointsDisplay />
            <PointsOfInterest />
            <ContentViewer metaverse={metaverse} />
            <SettingsOverlay />
            {/* <TutorialOverlay
              active={streamerStatus === StreamerStatus.Connected}
              ftueKey={`space:${space._id}`}
            /> */}
          </EmbeddedView>
        }
        {
          isLaunching && !isLocalPixelstreaming &&
          <LoadingText />
        }
        {
          !isLaunched && isReady && !isLocalPixelstreaming && hasUsage &&
          <LaunchContainer
            logo={metaverse?.logo}
            onEnter={launch}
            showBrowse={false}
            onBrowse={() => { }}
          />
        }
      </PureWebContainer>
      <PureWebAudioComponent />
      <UploadContentModalContainer
        isSiteAdmin={false}
        metaverseId={metaverseId}
      />
      <PureWebAvatarModal />
      <UsageLimitReachedModal isOwner={false} />
      <ServerBrowserModalContainer
        aliasId={aliasId}
        query={{ metaverseId, mapName }}
        onGameSessionIdChange={setGameSessionId}
      />
    </>
  );
}

export default function Space3DPageContainer({ page, metaverse }: Space3DPageProps) {
  const { projectId, modelId } = page.space.environment.purewebConfiguration;
  return (
    <UsageContextProvider metaverseId={page.metaverseId}>
      <PureWebContextProvider projectId={projectId} modelId={modelId}>
        <Space3DPage page={page} metaverse={metaverse} />
      </PureWebContextProvider>
    </UsageContextProvider>
  );
}
