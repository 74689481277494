import usePxContext from './usePxAuthContext';
import AuthModal from '../features/Visitor/components/Authentication/StandardAuthModal';
import styles from './RequireAuthentication.module.scss';

type Props = {
  additionalSSOs?: string[];
  children?: React.ReactNode;
};

export default function RequireAuthentication({ additionalSSOs = [], children }: Props) {
  const { isAuthenticated } = usePxContext();

  return isAuthenticated
    ? <>{children}</>
    : <AuthModal classes={{ modal: styles.modal }} open closable={false} additionalSSOs={additionalSSOs} />;
}
