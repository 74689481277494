import { StreamerStatus } from '@pureweb/platform-sdk';
import { useEffect } from 'react';

import usePxAuthContext from 'src/authentication/usePxAuthContext';
import Action from 'src/casl/Action';
import Subject from 'src/casl/Subject';
import PureWebAvatarModal from 'src/components/ReadyPlayerMe/PureWebAvatarModal';
import useCurrentMetaverse from 'src/features/Dashboard/hooks/useCurrentMetaverse';
import Background from 'src/features/PixelStreaming/components/Background/Background';
import ContentViewer from 'src/features/PixelStreaming/components/ContentViewer/ContentViewer';
import HUD from 'src/features/PixelStreaming/components/HUD';
import ImageSlotModal from 'src/features/PixelStreaming/components/ImageSlot/ImageSlotUploadModal';
import LaunchContainer from 'src/features/PixelStreaming/components/LaunchContainer/LaunchContainer';
import LoadingText from 'src/features/PixelStreaming/components/LoadingInfo/LoadingText';
import PointsDisplay from 'src/features/PixelStreaming/components/PointsDisplay';
import PointsOfInterest from 'src/features/PixelStreaming/components/PointsOfInterest';
import SceneSlotEditModal from 'src/features/PixelStreaming/components/SceneSlot/SceneSlotEditModal';
import SettingsOverlay from 'src/features/PixelStreaming/components/SettingsOverlay';
import TutorialOverlay from 'src/features/PixelStreaming/components/TutorialOverlay/TutorialOverlay';
import useDebugEmitUIInteract from 'src/features/PixelStreaming/hooks/useDebugEmitUIInteraction';
import useInitialPacket from 'src/features/PixelStreaming/hooks/useInitialPacket';
import useJoinSpace from 'src/features/PixelStreaming/hooks/useJoinSpace';
import useOpenBooth from 'src/features/PixelStreaming/hooks/useOpenBooth';
import useOpenExternalLink from 'src/features/PixelStreaming/hooks/useOpenExternalLink';
import useOpenMap from 'src/features/PixelStreaming/hooks/useOpenMap';
import useOpenModal from 'src/features/PixelStreaming/hooks/useOpenModal';
import useProfileUpdate from 'src/features/PixelStreaming/hooks/useProfileUpdate';
import useRefreshToken from 'src/features/PixelStreaming/hooks/useRefreshToken';
import useUserJoinAndExit from 'src/features/PixelStreaming/hooks/useUserJoinAndExit';
import EmbeddedView from 'src/features/PureWeb/components/EmbeddedView';
import PureWebAudioComponent from 'src/features/PureWeb/components/PureWebAudioComponent';
import PureWebContainer from 'src/features/PureWeb/components/PureWebContainer';
import { usePureWebContext } from 'src/features/PureWeb/contexts/PureWebContextProvider';
import UsageLimitReachedModal from 'src/features/Usage/components/UsageLimitReachedModal';
import UsageLimitReachedScreen from 'src/features/Usage/components/UsageLimitReachedScreen';
import { useUsageContext } from 'src/features/Usage/contexts/UsageContextProvider';
import UploadContentModalContainer from 'src/features/Visitor/components/UploadContentModal/UploadContentModalContainer';
import { ISpace, MetaverseResponse } from 'src/interfaces/IMetaverse';
import { getMetaverseEditURL } from 'src/utils/URLHelper';

interface Metaverse3DEditorPureWebSessionProps {
  metaverse: MetaverseResponse,
  space: ISpace;
}

export default function Metaverse3DEditorPureWebSession({ metaverse, space }: Metaverse3DEditorPureWebSessionProps) {
  const { user, authToken } = usePxAuthContext();
  const { metaverse: currentMetaverse, ability } = useCurrentMetaverse();

  const hasAdvancedUpload = ability.can(Action.Manage, Subject.AdvancedContentUpload);
  const canUploadImageSlot = ability.can(Action.Create, Subject.ImageSlot);

  const { _id: metaverseId } = metaverse;
  const { mapName } = space.environment;

  const {
    emitter,
    launch,
    isReady,
    isLaunching,
    isLaunched,
    isLocalPixelstreaming,
    videoStream,
    streamerStatus,
    status,
  } = usePureWebContext();

  useAutoLaunch();

  const { hasUsage } = useUsageContext();

  useInitialPacket(
    metaverseId,
    currentMetaverse?.organizationId,
    authToken,
    user,
    null,
  );

  useRefreshToken();
  useOpenExternalLink();
  useJoinSpace(space._id);
  useOpenModal();
  useProfileUpdate();
  useDebugEmitUIInteract();
  useUserJoinAndExit();
  useOpenBooth();
  useOpenMap(mapName);

  const handleTimeout = () => {
    window.location.href = getMetaverseEditURL(metaverse);
  };

  return (
    <PureWebContainer>
      {
        space.background && streamerStatus !== StreamerStatus.Connected && (
          <Background source={space.background} />
        )
      }
      <UsageLimitReachedScreen isOwner logo={metaverse?.logo} />
      {
        hasUsage &&
        <EmbeddedView
          videoStream={videoStream}
          streamerStatus={streamerStatus}
          inputEmitter={emitter}
          onTimeout={handleTimeout}
        >
          <HUD globeButtonEnabled={false} />
          <PointsDisplay />
          <PointsOfInterest />
          <SettingsOverlay />
          <ContentViewer metaverse={metaverse} />
          {/* <TutorialOverlay
            active={streamerStatus === StreamerStatus.Connected}
            ftueKey="editor"
          /> */}
        </EmbeddedView>
      }
      {
        isLaunching && !isLocalPixelstreaming &&
        <LoadingText />
      }
      {
        !isLaunched && isReady && !isLocalPixelstreaming && hasUsage &&
        <LaunchContainer
          logo={metaverse.logo}
          onEnter={launch}
          showBrowse={false}
          onBrowse={() => { }}
        />
      }
      <PureWebAudioComponent />
      <UploadContentModalContainer
        isSiteAdmin={hasAdvancedUpload}
        metaverseId={metaverseId}
      />
      <UsageLimitReachedModal isOwner onDismiss={handleTimeout} />
      <PureWebAvatarModal />
      <ImageSlotModal
        metaverseId={metaverse._id}
        spaceId={space._id}
        isSiteAdmin={canUploadImageSlot}
      />
      <SceneSlotEditModal
        metaverseId={metaverse._id}
        spaceId={space._id}
      />
    </PureWebContainer>
  );
}

function useAutoLaunch() {
  const { hasUsage } = useUsageContext();

  const {
    launch,
    isLaunching,
    isLaunched,
  } = usePureWebContext();

  useEffect(() => {
    if (!isLaunched && !isLaunching && launch && hasUsage) launch();
  }, [launch, isLaunched, isLaunching, hasUsage]);
}
