import LoadingSpinner from '../LoadingSpinner';
import styles from './LoadingInfo.module.scss';

export default function LoadingText() {
  return (
    <div className={styles.container}>
      <LoadingSpinner className={styles.info} />
      <p className={styles.info}>Loading...</p>
    </div>
  );
}
