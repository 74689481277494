import usePxContext from './usePxAuthContext';
import AuthModal from '../features/Visitor/components/Authentication/StandardAuthModal';
import styles from './RequireAuthentication.module.scss';
import useAccessMetaverse from 'src/features/Visitor/hooks/useAccessMetaverse';

type Props = {
  overrideSSOs?: string[];
  children?: React.ReactNode;
};

export default function RequireMetaverseAuthentication({ overrideSSOs, children }: Props) {
  const { metaverse } = useAccessMetaverse();
  const { isAuthenticated } = usePxContext();

  const additionalSSOs = overrideSSOs ?? metaverse?.additionalSSOs ?? [];

  console.log(metaverse);

  return isAuthenticated
    ? <>{children}</>
    : <AuthModal classes={{ modal: styles.modal }} open closable={false} additionalSSOs={additionalSSOs} />;
}
