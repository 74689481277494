/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocalStorage } from 'react-use';
import { ethers } from 'ethers';
import * as jose from 'jose';
import { Web3ReactProvider } from '@web3-react/core';
import useAsyncEffect from 'use-async-effect';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { UserDocument } from 'src/interfaces/IUser';
import { dataLayer } from 'src/utils/DataLayerUtil';
import { useMe } from 'src/queries/me';
import { setUserProfile } from '../redux/userProfile';
import IAuthContext, { LogoutOptions } from './IAuthContext';
import api from '../services/api';
import { getBaseOrigin } from '../utils/URLHelper';
import LoginMethod from './LoginMethod';

interface StoredTokenResponse {
  body: {
    access_token: string;
    token_type: 'Bearer';
    expires_in: number;
    refresh_token: string;
    scope?: string[];
    id_token?: string;
    decodedToken: {
      header: jose.ProtectedHeaderParameters,
      claims: jose.JWTPayload,
    }
  },
  expiresAt: number;
}

export const InternalAuthContext = React.createContext<IAuthContext>(null!);

type InternalAuthContextProps = {
  children: React.ReactNode;
};

interface SignUpOptions {
  returnTo?: string;
}

const InternalAuthContextProvider = ({ children }: InternalAuthContextProps) => {
  const dispatch = useDispatch();
  const [storedTokenResponse, setStoredTokenResponse, removeStoredTokenResponse] =
    useLocalStorage<StoredTokenResponse>('pixelplatformtoken');
  const [hasRefreshInterceptor, setHasRefreshInterceptor] = useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [redirectTo, setRedirectTo] = useState<string>();
  const { data: user, isSuccess, refetch, remove } = useMe(
    !!storedTokenResponse?.body?.decodedToken?.claims?.sub && hasRefreshInterceptor,
  );

  useEffect(() => {
    if (!storedTokenResponse?.body?.decodedToken?.claims?.sub) return;
    refetch();
  }, [storedTokenResponse?.body?.decodedToken?.claims?.sub]);

  useEffect(() => {
    if (!isSuccess) return;
    if (!user) {
      dispatch(setUserProfile(null));
      return;
    }
    dataLayer.push({ event: 'user_id_set', user_id: user._id });
    dispatch(setUserProfile(user));
  }, [user, isSuccess]);

  const saveTokenResponse = (tokenResponse: any) => {
    const accessToken = tokenResponse.access_token;
    const protectedHeader = jose.decodeProtectedHeader(accessToken);
    const claims = jose.decodeJwt(accessToken);
    setStoredTokenResponse({
      body: {
        ...tokenResponse,
        decodedToken: {
          header: protectedHeader,
          claims,
        },
      },
      expiresAt: claims.exp || Math.floor(Date.now() / 1000),
    });
  };

  useAsyncEffect(async () => {
    const encodedTokenResponse = searchParams.get('tokenresponse');
    if (encodedTokenResponse) {
      searchParams.delete('tokenresponse');
      setSearchParams(searchParams);
      const tokenResponse = JSON.parse(window.atob(encodedTokenResponse));
      saveTokenResponse(tokenResponse);
    }
  }, [searchParams]);

  useEffect(() => {
    if (storedTokenResponse?.expiresAt &&
        storedTokenResponse.expiresAt < (Date.now() / 1000)) {
      removeStoredTokenResponse();
    }
  }, [storedTokenResponse?.expiresAt]);

  const signup = async (email: string, password: string, opts: SignUpOptions) => {
    const res = await api.post('/api/v2/auth/signup', {
      ...opts, email, password, returnTo: opts.returnTo ?? window.location.pathname + window.location.search,
    });
    const tokenResponse = res.data;
    saveTokenResponse(tokenResponse);
  };

  const loginWithEmailPassword = async (email: string, password: string) => {
    const res = await api.post('/api/v2/auth/login', { email, password });
    const tokenResponse = res.data;
    saveTokenResponse(tokenResponse);
  };

  const logout = async (options: LogoutOptions = {}) => {
    console.debug('Logging out...');
    removeStoredTokenResponse();
    dispatch(setUserProfile(null));
    await api.post('/api/v2/auth/logout');
    remove();
    setRedirectTo(options.returnTo ?? getBaseOrigin());
  };

  useEffect(() => {
    if (!redirectTo) return;
    if (redirectTo !== '/' && redirectTo !== window.location.origin) {
      window.open(redirectTo, '_self');
    } else {
      navigate('/');
    }
  }, [redirectTo]);

  const loginWithGoogle = async (returnTo?: string) => {
    const params = new URLSearchParams();
    params.append('returnTo', returnTo ?? window.location.href);
    window.open(`/api/auth/oauth2/google/login?${params.toString()}`, '_self');
  };

  const loginWithOAuth2 = async (slug: string, returnTo?: string) => {
    const params = new URLSearchParams();
    params.append('returnTo', returnTo ?? window.location.href);
    window.open(`/api/auth/oauth2/${slug}/login?${params.toString()}`, '_self');
  }

  const login = async (method: LoginMethod, opts: any = {}) => {
    console.debug(`Logging in with ${method}`);
    switch (method) {
      case LoginMethod.EmailPassword: {
        const { email, password } = opts;
        if (!email) {
          throw new Error('Email cannot be blank');
        }
        if (!password) {
          throw new Error('Password cannot be blank');
        }
        await loginWithEmailPassword(email, password);
        break;
      }
      case LoginMethod.GoogleOAuth2: {
        const { returnTo } = opts;
        await loginWithGoogle(returnTo);
        break;
      }
      case LoginMethod.OAuth2: {
        const { returnTo } = opts;
        await loginWithOAuth2(opts.slug, returnTo);
        break;
      }
      default:
        console.error(`Unknown login method ${method}`);
        break;
    }
  };

  const refreshToken = async () => {
    try {
      const refreshResponse = await api.post('/api/v2/auth/refresh');
      saveTokenResponse(refreshResponse.data);
    } catch (e: any) {
      removeStoredTokenResponse();
      throw e;
    }
  };

  useEffect(() => {
    if (!storedTokenResponse?.body?.decodedToken?.claims?.sub) return;
    api.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error.response?.status === 401 &&
          !originalRequest._retry &&
          originalRequest.url !== '/api/v2/auth/refresh') {
          originalRequest._retry = true;
          refreshToken();
          return api(originalRequest);
        }
        return Promise.reject(error);
      },
    );
    setHasRefreshInterceptor(true);
  }, [api, storedTokenResponse?.body?.decodedToken?.claims?.sub]);

  const value: IAuthContext = {
    authToken: storedTokenResponse?.body?.access_token,
    signup,
    login,
    loginMethods: [LoginMethod.EmailPassword, LoginMethod.GoogleOAuth2],
    isAuthenticated: Boolean(storedTokenResponse),
    user: user as UserDocument, // TODO: Remove this cast once we have everything handling the possible null on user
    logout,
    saveTokenResponse,
    refreshToken,
  };

  return (
    <InternalAuthContext.Provider value={value}>
      {children}
    </InternalAuthContext.Provider>
  );
};

const Web3AuthProvider = ({ children }: { children: React.ReactNode }) => (
  <Web3ReactProvider getLibrary={(provider) => new ethers.providers.Web3Provider(provider)}>
    <InternalAuthContextProvider>{children}</InternalAuthContextProvider>
  </Web3ReactProvider>
);

export default Web3AuthProvider;
