import { StreamerStatus } from '@pureweb/platform-sdk';
import { useEffect, useState } from 'react';

import ContentViewer from 'src/features/PixelStreaming/components/ContentViewer/ContentViewer';
import HUD from 'src/features/PixelStreaming/components/HUD';
import PointsOfInterest from 'src/features/PixelStreaming/components/PointsOfInterest';
import ServerBrowserModalContainer from 'src/features/PixelStreaming/components/ServerBrowserModalContainer';
import SettingsOverlay from 'src/features/PixelStreaming/components/SettingsOverlay';
import TutorialOverlay from 'src/features/PixelStreaming/components/TutorialOverlay/TutorialOverlay';
import useDebugEmitUIInteract from 'src/features/PixelStreaming/hooks/useDebugEmitUIInteraction';
import useInitialPacket from 'src/features/PixelStreaming/hooks/useInitialPacket';
import useJoinGameSession from 'src/features/PixelStreaming/hooks/useJoinGameSession';
import useOpenExternalLink from 'src/features/PixelStreaming/hooks/useOpenExternalLink';
import useOpenModal from 'src/features/PixelStreaming/hooks/useOpenModal';
import useProfileUpdate from 'src/features/PixelStreaming/hooks/useProfileUpdate';
import useRefreshToken from 'src/features/PixelStreaming/hooks/useRefreshToken';
import useUserJoinAndExit from 'src/features/PixelStreaming/hooks/useUserJoinAndExit';
import PureWebAudioComponent from 'src/features/PureWeb/components/PureWebAudioComponent';
import PureWebContextProvider, { usePureWebContext } from 'src/features/PureWeb/contexts/PureWebContextProvider';
import UsageLimitReachedModal from 'src/features/Usage/components/UsageLimitReachedModal';
import UsageLimitReachedScreen from 'src/features/Usage/components/UsageLimitReachedScreen';
import UsageContextProvider, { useUsageContext } from 'src/features/Usage/contexts/UsageContextProvider';
import { MetaverseResponse } from 'src/interfaces/IMetaverse';
import { PureWebPage } from 'src/interfaces/IPage';
import { useGetRandomGameLiftSession } from 'src/queries/gamelift';
import usePxAuthContext from '../../authentication/usePxAuthContext';
import Background from '../../features/PixelStreaming/components/Background/Background';
import LaunchContainer from '../../features/PixelStreaming/components/LaunchContainer/LaunchContainer';
import EmbeddedView from '../../features/PureWeb/components/EmbeddedView';
import PureWebContainer from '../../features/PureWeb/components/PureWebContainer';
import PureWebAvatarModal from '../ReadyPlayerMe/PureWebAvatarModal';
import PointsDisplay from 'src/features/PixelStreaming/components/PointsDisplay';
import LoadingText from 'src/features/PixelStreaming/components/LoadingInfo/LoadingText';

export interface ThreeDPageProps {
  page: PureWebPage;
  metaverse: MetaverseResponse;
  environmentId?: string;
  endpoint?: string;
  forceRelay?: boolean;
  launchType?: string;
}

function ThreeDPage({ page, metaverse }: ThreeDPageProps) {
  const { metaverseId } = page;

  const { hasUsage } = useUsageContext();

  const { mapName, purewebConfiguration } = page;
  const { aliasId } = purewebConfiguration;

  const [gameSessionId, setGameSessionId] = useState<string>();
  const { data: randomGameLiftSession } = useGetRandomGameLiftSession(metaverseId, aliasId, mapName);

  useEffect(() => {
    if (!randomGameLiftSession) return;
    setGameSessionId(randomGameLiftSession.GameSessionId);
  }, [randomGameLiftSession]);

  const {
    launch,
    isReady,
    isLaunching,
    isLaunched,
    isLocalPixelstreaming,
    videoStream,
    streamerStatus,
    emitter,
    status,
  } = usePureWebContext();

  const { user, authToken } = usePxAuthContext();

  useJoinGameSession(gameSessionId);
  useInitialPacket(
    metaverseId,
    undefined,
    authToken,
    user,
    gameSessionId,
  );
  useRefreshToken();
  useOpenExternalLink();
  useOpenModal();
  useProfileUpdate();
  useDebugEmitUIInteract();
  useUserJoinAndExit();

  console.log(isLaunched, isReady, isLocalPixelstreaming, hasUsage);

  return (
    <>
      <PureWebContainer>
        {streamerStatus !== StreamerStatus.Connected && <Background source={{ srcType: page.preloadSourceType, src: page.preloadSource }} />}
        <UsageLimitReachedScreen isOwner={false} logo={metaverse?.logo} />
        {
          hasUsage &&
          <EmbeddedView
            videoStream={videoStream}
            streamerStatus={streamerStatus}
            inputEmitter={emitter}
          >
            <HUD />
            <PointsOfInterest />
            <PointsDisplay />
            <ContentViewer metaverse={metaverse} />
            <SettingsOverlay />
            {/* <TutorialOverlay
              active={streamerStatus === StreamerStatus.Connected}
              ftueKey={`page:${page._id}`}
            /> */}
          </EmbeddedView>
        }
        {
          isLaunching && !isLocalPixelstreaming &&
          <LoadingText />
        }
        {
          !isLaunched && isReady && !isLocalPixelstreaming && hasUsage &&
          <LaunchContainer
            logo={metaverse.logo}
            onEnter={launch}
            showBrowse={false}
            onBrowse={() => { }}
          />
        }
      </PureWebContainer>
      <PureWebAudioComponent />
      <PureWebAvatarModal />
      <UsageLimitReachedModal isOwner={false} />
      <ServerBrowserModalContainer
        aliasId={aliasId}
        query={{ metaverseId, mapName }}
        onGameSessionIdChange={setGameSessionId}
      />
    </>
  );
}

export default function ThreeDPageContainer({
  page,
  metaverse,
  environmentId,
  forceRelay = false,
  launchType = window.env.REACT_APP_PW_ENVIRONMENT_ID ? 'local' : undefined,
}: ThreeDPageProps) {
  const { modelId, projectId } = page.purewebConfiguration;
  return (
    <UsageContextProvider metaverseId={page.metaverseId}>
      <PureWebContextProvider projectId={projectId} modelId={modelId}>
        <ThreeDPage
          page={page}
          metaverse={metaverse}
          environmentId={environmentId}
          forceRelay={forceRelay}
          launchType={launchType}
        />
      </PureWebContextProvider>
    </UsageContextProvider>
  );
}
