import { Page, PageContent } from '@pixelcanvas/ui';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import EditMetaverseSideNav from '../../components/EditMetaverseSideNav';
import MetaverseHeader from '../../components/MetaverseHeader/MetaverseHeader';
import ShareModal from '../../components/ShareModal/ShareModal';
import OrganizationContextProvider, { useOrganizationContext } from '../../pages/OrganizationPage/context/OrganizationContextProvider';
import { selectPageContentBackground, selectTitle } from '../../redux/editMetaverse';
import useCurrentMetaverse from '../../hooks/useCurrentMetaverse';
import { useEffect } from 'react';
import RequireMetaverseAuthentication from 'src/authentication/RequireMetaverseAuthentication';

export default function EditMetaverse() {
  const title = useSelector(selectTitle);
  const pageContentBackground = useSelector(selectPageContentBackground);

  return (
    <RequireMetaverseAuthentication>
      <OrganizationContextProvider>
        <EditMetaversePage />
        <ShareModal />
      </OrganizationContextProvider>
    </RequireMetaverseAuthentication>
  );
}

function EditMetaversePage() {
  const title = useSelector(selectTitle);
  const pageContentBackground = useSelector(selectPageContentBackground);
  const { metaverse } = useCurrentMetaverse();
  const { changeCurrentOrganization } = useOrganizationContext();

  useEffect(() => {
    if (!metaverse?.organizationId) return;
    changeCurrentOrganization(metaverse.organizationId);
  }, [metaverse?.organizationId]);

  return (
    <Page>
      <EditMetaverseSideNav />
      <PageContent background={pageContentBackground}>
        <MetaverseHeader title={title} />
        <Outlet />
      </PageContent>
    </Page>
  )
}
